import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {theme} from 'common/theme/appminimal';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {ResetCSS} from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/AppMinimal/Navbar';
import Footer from 'containers/AppMinimal/Footer';
import Seo from 'components/seo';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
    GradientWrapper,
} from 'containers/AppMinimal/app-minimal.style';

const Terms = () => {


    useEffect(() => {

    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Terms & Conditions"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active" className="black-sticky noHarp">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "80%", margin: "auto", textAlign: "justify", paddingTop: "80px"}}>
                            <h1>Terms of Service</h1>
                            <p>These Terms of Service ("Terms") govern your access to and use of all content, products,
                                and services available at OnwardTicketPro – onwardticketpro.com (the “Service”), which
                                is operated by OnwardTicketPro ("us", "we", or "our").</p>
                            <p>By accessing or using any part of our Service, you agree to be bound by these Terms. If
                                you do not agree with any part of the Terms, you are prohibited from using or accessing
                                the Service.</p>

                            <h2>Intellectual Property Rights</h2>
                            <p>The Agreement does not transfer any OnwardTicketPro or third-party intellectual property
                                to you. All rights, titles, and interests in and to such property remain solely with
                                OnwardTicketPro and its licensors.</p>

                            <h2>Third-Party Services</h2>
                            <p>While using our Service, you may interact with third-party services, products, software,
                                embeds, or applications developed by third parties ("Third-Party Services").</p>
                            <p>By using any Third-Party Services, you acknowledge and agree that:</p>
                            <ul>
                                <li>Any use of Third-Party Services is at your own risk. We are not responsible or
                                    liable for any Third-Party websites or services.
                                </li>
                                <li>We are not responsible or liable for any damage or loss caused or alleged to be
                                    caused by or in connection with the use of any content, goods, or services available
                                    on or through any such websites or services.
                                </li>
                            </ul>

                            <h2>User Accounts</h2>
                            <p>To use certain features of our Service, you may be required to create an account. You
                                agree to provide accurate, complete, and current information during the registration
                                process.</p>
                            <p>You are responsible for maintaining the confidentiality of your account credentials and
                                for all activities that occur under your account. You must promptly notify us of any
                                unauthorized use of your account or any other breach of security.</p>

                            <h2>External Links</h2>
                            <p>Our Service may contain links to external websites or services that are not owned or
                                controlled by OnwardTicketPro.</p>
                            <p>We do not have control over, and assume no responsibility for, the content, privacy
                                policies, or practices of any third-party websites or services. We are not liable for
                                any damage or loss caused or alleged to be caused by or in connection with the use of or
                                reliance on any such content, goods, or services available on or through any third-party
                                websites or services.</p>
                            <p>We strongly recommend that you read the terms and conditions and privacy policies of any
                                third-party websites or services you visit.</p>

                            <h2>Termination</h2>
                            <p>We may terminate or suspend your access to all or any part of our Service at any time,
                                with or without cause, with or without notice, effective immediately.</p>
                            <p>If you wish to terminate the Agreement or your OnwardTicketPro account, you may
                                discontinue using our Services at any time.</p>
                            <p>All provisions of the Agreement that by their nature should survive termination will
                                survive termination, including, without limitation, ownership provisions, warranty
                                disclaimers, indemnity, and limitations of liability.</p>

                            <h2>Disclaimer</h2>
                            <p>Our Service is provided on an "AS IS" and "AS AVAILABLE" basis. OnwardTicketPro and its
                                suppliers and licensors hereby disclaim all warranties of any kind, express or implied,
                                including, without limitation, the warranties of merchantability, fitness for a
                                particular purpose, and non-infringement. Neither OnwardTicketPro nor its suppliers and
                                licensors make any warranty that the Service will be error-free or that access will be
                                continuous or uninterrupted.</p>
                            <p>You understand that you download from, or otherwise obtain content or services through,
                                our Service at your own discretion and risk.</p>

                            <h2>Governing Law</h2>
                            <p>Except to the extent any applicable law provides otherwise, these Terms and any access to
                                or use of the Service will be governed by the laws of Barcelona, Spain.</p>
                            <p>The proper venue for any disputes arising out of or relating to these Terms and any
                                access to or use of the Service will be the state and federal courts located in
                                Barcelona, Spain.</p>

                            <h2>Modifications</h2>
                            <p>OnwardTicketPro reserves the right, at our sole discretion, to modify or replace these
                                Terms at any time.</p>
                            <p>If we make changes that are material, we will notify you by posting on our website or by
                                sending you an email or other communication before the changes take effect. The notice
                                will designate a reasonable period after which the new terms will take effect.</p>
                            <p>We will try to provide at least 30 days' notice before any material changes take effect.
                                If you disagree with our changes, you should stop using our Service within the
                                designated notice period, or once the changes become effective.</p>
                            <p>Your continued use of our Service following the effective date of any changes constitutes
                                your acceptance of those changes.</p>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                        <Footer/>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default Terms;
